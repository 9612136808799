
import './App.css';
import Contact from './Components/Forms/Contact';
import Ui from './Components/UI/Ui';

function App() {
  return (
    <div className="App">

   <Ui/>




</div>
  );
}

export default App;
